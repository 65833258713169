import axios from "axios";
import store from "@/store"
const request = axios.create({
    withCredentials: true,
    baseURL:store.state.baseUrl,
    timeout:10000
})
request.interceptors.request.use(config=>{
    config.headers['Content-Type']="application/json;charset=utf-8";
    return config;
},error => {
    return Promise.reject(error);
});

request.interceptors.response.use(res=>{
    return res.data;
},error => {
    return Promise.reject(error);
})
export default request;
