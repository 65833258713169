<template>
  <div class="home">
    <div class="content">
      <Head/>
      <Body/>
      <div class="lk">
        <span class="mail-e" style="margin-top: 10px">联系我: 1916614258@qq.com</span>
      </div>
    </div>
    <div class="_bottom">
      <span class="copy-text">Copyright &copy; 2023 qihe</span>
      <span >
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36112802000123"
                   style="margin-left: 10px;margin-top: 10px;display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
                    src="/icons/beian.ico" style="float:left;"/><p
                    style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">赣公网安备 36112802000123号</p></a>
      </span>
      <span class="ba">
        <span> ICP备案: </span>
        <span class="ba-a">赣ICP备2023004297号</span>
        | <a class="ba-a" href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2023004297号-1</a>
      </span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Head from "@/components/home/Head";
import Body from "@/components/home/Body";

export default {
  name: 'HomeView',
  components: {
    Head,
    Body
  }
}
</script>
<style>
.mail-e {
  margin-top: 20px;
}

.lk {
  text-align: left;
  margin-top: 10px;
  background: #2F333A;
  height: 100px;
  color: white;
}

._bottom {
  background: #2A2A2A;
  color: white;
  height: 50px;
}

.ba {
  margin-left: 10px;
}

.ba-a {
  margin-bottom: 20px;
  color: white;
  text-decoration: white;
}
</style>