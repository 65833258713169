<template>
  <div>
    <div style="background: #D0FAD5"><h1>开发程序展示</h1></div>
    <el-carousel :interval="3000" type="card" height="250px">
      <el-carousel-item class="carousel" v-for="item in images" :key="images">
        <el-popover
            placement="top"
            :width="80"
            trigger="hover"
            content="点击查看详情"
        >
          <template #reference>
            <img :src="item.src" class="head-img" style="height: 80%" @click="toDetail(item.id)"/>
          </template>
        </el-popover>
      </el-carousel-item>
    </el-carousel>
  </div>

</template>

<script>
import router from "@/router";

export default {
  name: "homeHead",
  data() {
    return {
      images: [
          {
            id:2,
            src: '/images/pa.png'
          },
        {
          id:2,
          src: '/images/textView.png',
        },
        {
          id:2,
          src: '/images/textView-d.png',
        },
        {
          id:2,
          src: '/images/pr.png',
        },
        {
          id:2,
          src: '/images/sp.png',
        },
      ]
    }
  },
  methods: {
    toDetail(id) {
      router.push("/product/" + id);
    }
  }
}
</script>

<style scoped>
.head-img {
  margin-top: 5%;
}

.el-carousel__item h3 {
  color: white;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: white;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: white;
}

.carousel {
  height: 100%;
}
</style>