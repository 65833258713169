<template>
  <div class="body">
    <el-card class="card" v-for="card in cardContents">
      <img
          src=""
          class="image"
      />

      <div style="padding: 14px">
        <el-scrollbar height="400px">
          <span>{{ card.title }}</span>
          <div class="bottom">
            <p v-for="item in card.items">
                <span class="sub_title" >
                  {{ item.title }}
                </span>
              <el-popover
                  v-for="subItem in item.items"
                  placement="top-start"
                  :title="subItem.name"
                  :width="300"
                  trigger="hover"
                  :content="subItem.comment"
              >
                <template #reference scope="">
            <p >{{subItem.name}}</p>
</template>
</el-popover>
</p>
<!--<el-button text class="button">Operating</el-button>-->
</div>
        </el-scrollbar>

      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "Body",
  data() {
    return {
      cardContents: [
        {
          title: '书单推荐',
          items: [
            {
              title: `* 改造世界观`,
              items: [
                {
                  name: '《马克思主义基本原理概论》',
                  comment: `认识世界改造世界`
                },
                {
                  name: '《毛泽东选集》',
                  comment: `看伟大的人如何伟大`
                },
              ],
            }, {
              title: `* 教做人`,
              items: [
                {
                  name: '《孙子兵法》',
                  comment: `每个人收获都不一样。印象最深:主不可以怒而兴师，将不可以愠而致战；合利而动，不合利而止。怒可以复喜，愠可以复乐，死者不可以复生`
                },
                {
                  name: '《论语》',
                  comment: `取其精华，去其糟粕；以否定之否定的观念去看`
                },
                {
                  name: '《死人经》',
                  comment: `题材: 小说
                  其中有很多作者的思考，值得一读`
                },
              ]
            }, {
              title: `* 编程学习`,
              items: [
                {
                  name: '《c语言程序设计现代方法》',
                  comment: `建议先找C的视频看后再读此书，很不错。无论学什么语言，先了解下C都是有帮助的`
                },
                {
                  name: '《计算机网络原理》',
                  comment: `破除网络神秘面纱，了解为啥调用几个API就能进行远程通讯。在我们看不见的的地方有多少设备在工作，将这些一一介绍。让你在编程时一眼就看出底层结构`
                }, {
                  name: '《软件工程》',
                  comment: `从工程的角度看待软件开发，使软件开发具有结构层次化。减少出错，层层递进，完成从概念到program的映射`
                },
                {
                  name: '《编译原理》',
                  comment: `从比较底层的角度讲述，用c，java等语言编写的程序为什么能运行起来，一定程度上破除编程的神秘面纱，对算法设计有一定帮助，让人感觉编程能把握的住。`
                },
              ]
            },
          ]
        },
        {
          title: '视频推荐',
          items: [
            {
              title: `* 动漫`,
              items: [
                {
                  name: '《紫罗兰永恒花园》',
                  comment: `总能有一个片段让你被感动到`
                },

              ],
            }, {
              title: `* 编程学习`,
              items: [
                {
                  name: '《循序渐进学java》韩顺平老师',
                  comment: `细到你发麻，非常的良心`
                },
                {
                  name: '《软件工程》北京大学',
                  comment: `只看书和只看视频都很难进入状态，二者相结合会有1+1>2的效果。`
                },
                {
                  name: '《编译原理》国防科技大学',
                  comment: `超级超级推荐，该老师将课水平很高，课件也很用心，用心100%掌握`
                },
                {
                  name: '《离散数学》北京大学',
                  comment: `有难度我没学完，但是老师讲的很好`
                },
                {
                  name: '《数据结构和算法》韩顺平老师',
                  comment: `介绍的10大算法对每位学java的人都很友好，刷算法题前建议学习`
                },
                {
                  name: '《linux学习入门》韩顺平老师',
                  comment: `通俗易懂，值得推荐`
                },
              ]
            },{
              title: `* 英语学习`,
              items: [
                {
                  name: '《英语入门课程》Nickcen',
                  comment: `从语法的角度，讲解英语，本人本人英语白痴，感觉挺有用 `
                },
              ],
            }
          ]
        },
        {
          title: '座右铭',
          items: [
            {
              title: `* 自我改造`,
              items: [
                {
                  name: '痛苦时',
                  comment: `故天将降大任于是人也，必先苦其心志，劳其筋骨，饿其体肤，空乏其身，行拂乱其所为，所以动心忍性，曾益其所不能。`
                },
                {
                  name: '安逸时',
                  comment: `居安思危，思则有备，有备无患`
                },
                {
                  name: '堕落时',
                  comment: `我是一个伟大的人!`
                },
                {
                  name: '迷茫时',
                  comment: `自立根生，艰苦奋斗，自强不息。
好好学习，珍惜机会，决心解决理想与现实之间的矛盾。
脚踏实地，一步一个脚印，实事求是。
自己会骗自己，其他人会骗自己，你的作为，客观的反映你。
事做对了要总结提高。做错了要勇于承担，重要的是要改正，要自省，但不能死脑壳。`
                },
                {
                  name: '失败时',
                  comment: `真理在不断失败中逐露出真容，
失败并不可怕，害怕失败才可怕。`
                },
                {
                  name: '绝望时',
                  comment: `在无尽的绝望中不断前进，那是最大的浪漫啊!`
                },
                {
                  name: '空虚时',
                  comment: `我的人生绝不能是，我来过-我走了。
而是要实现我的价值，为人民服务。
世界没有我一样转，但是有我会转的更好！`
                },
                {
                  name: '沮丧时',
                  comment: `让他们去说我们这也不行，那也不行吧！中国人民不屈不挠的努力，必将稳步的地达到自己的目的！`
                },
              ],
            },
          ]
        },
        {
          title: '我的编程技术栈',
          items: [
            {
              title: `* 前端`,
              items: [
                {
                  name: 'html',
                  comment: `浏览器能解析的页面格式`
                },
                {
                  name: 'css',
                  comment: `指定浏览器解析的样式`
                },
                {
                  name: 'js',
                  comment: `动态前端的基石`
                },
                {
                  name: 'jquery',
                  comment: `js的封装库`
                },
                {
                  name: 'dom编程',
                  comment: `js操作的底层概念`
                },
                {
                  name: 'promise',
                  comment: `新的异步请求模型`
                },
                {
                  name: 'vue',
                  comment: `前端开发框架，对js的封装`
                },
                {
                  name: 'json',
                  comment: `数据交互格式`
                },
                {
                  name: 'vue',
                  comment: `前端开发框架，对js的封装`
                },
                {
                  name: 'ajax',
                  comment: `异步请求方式`
                },
                {
                  name: 'axios',
                  comment: `ajax的封装`
                },
                {
                  name: 'http 协议',
                  comment: `前后端数据传输协议`
                },
              ],
            }, {
              title: `* 后端`,
              items: [
                {
                  name: 'java基础',
                  comment: `1. 8大数据结构
    2. 线程
    3. 网络
    4. 容器
    5. 反射
    6. 动态代理
    7. ThreadLocal
    8. java程序结构
    9. 注解
    10. 泛型
    11. 接口
    12. 等等`
                },
                {
                  name: 'http协议',
                  comment: `前后端数据传输协议`
                },
                {
                  name: 'tomcat',
                  comment: `http交互容器中间件`
                },
                {
                  name: 'xml',
                  comment: `配置文件常用格式`
                },
                {
                  name: '三大原生web组件',
                  comment: `1. Servlet
    2. Filter
    3. Listener`
                },
                {
                  name: 'jsp 能看懂能维护',
                  comment: `tomcat动态渲染的html，扩展了html的功能`
                },
                {
                  name: 'mysql',
                  comment: `神级数据库`
                },
                {
                  name: 'spring',
                  comment: `新概念的对象容器，重新定义对象及对象操作`
                },
                {
                  name: 'springmvc',
                  comment: `基于spring的mvc三层架构，使程序操作更具结构。简化了请求数据的封装，减轻了程序员的工作量。
                  其设计思想非常的牛，适合所有的通讯业务`
                },
                {
                  name: 'springboot',
                  comment: `基于spingmvc 对mvc和tomcat进行整合，spring框架的集大成者。其思想，约定优于配置，极大的简化了程序的配置工作，是程序员能更加集中精力处理业务需求。`
                },
                {
                  name: 'Thymeleaf',
                  comment: `springboot支持的一个类jsp的html页面的渲染组件`
                },
                {
                  name: 'mybatis，mybatis—plus',
                  comment: ` 对数据库的操作进行结构，极大的减轻了编写sql语句的工作量。完成sql语句和程序的解耦`
                },
                {
                  name: 'postman',
                  comment: `后端测试工具，有良好的ui`
                },
                {
                  name: 'jmeter',
                  comment: `并发测试工具，自由度比较高。 ui比较简洁，看起来很强`
                },
                {
                  name: 'springCloud和cloudAlibaba',
                  comment: `1. 分布式项目的一套解决方案
    2. gateway
    3. openfeign
    4. ribbon
    5. Sleuth&&zipkin
    6. Nacos
    7. sentinel
    8. Seata
    9. Alibaba OSS`
                },
                {
                  name: 'linux 能使用',
                  comment: `后端程序的生产运行环境`
                },
                {
                  name: 'git',
                  comment: `项目管理`
                },
                {
                  name: 'nginx',
                  comment: `网关；反向代理`
                },
                {
                  name: 'redis',
                  comment: `强大的内存容器，分布式项目可以作为一个全局容器控件，解决分布式session。做为缓存提高服务器性能`
                },
                {
                  name: 'rabbitmq',
                  comment: `队列技术的典型，有效对请求进行削峰，主要用来减轻db压力`
                },
                {
                  name: 'maven',
                  comment: `包管理技术，能使用`
                },
              ],

            },
          ]
        },

      ]
    }
  }
}
</script>

<style scoped>
.body {
  margin-top: 10px;
  background: white;
  height: 500px;
  display: flex;
}

.time {
  font-size: 12px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  /*display: flex;*/
  justify-content: space-between;
  text-align: left;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.sub_title{
  font-size: 120%;
}

.card {
  margin-left: 10px;
  width: 24%;
}
</style>